import { CareForm } from "@components/forms/careForm";
import { Query } from "@graphql-types";
import { Header } from "@shared";
import { PageWidth, Section } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React from "react";

interface Props extends PageProps {
  data: Query;
}

const Care = ({ data: { sanityHeader, allSanityVillage, allSanityRegion } }: Props) => {
  return (
    <>
      <Header data={sanityHeader} />
      <Section>
        <PageWidth>
          <CareForm villages={allSanityVillage.nodes} regions={allSanityRegion.nodes} />
        </PageWidth>
      </Section>
      ;
    </>
  );
};

export default Care;

export const query = graphql`
  query eventRegistrationQuery {
    sanityHeader {
      ...sanityHeader
    }
    allSanityRegion(sort: { order: ASC, fields: order }) {
      nodes {
        order
        title
        marketoValue
        slug {
          current
        }
        isParentRegion
        parentRegion {
          title
          slug {
            current
          }
        }
      }
    }
    allSanityVillage(sort: { fields: title, order: ASC }) {
      nodes {
        title
        canBookATour
        villageCode
        region {
          order
          title
          marketoValue
          slug {
            current
          }
          isParentRegion
          parentRegion {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
